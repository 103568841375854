import { render, staticRenderFns } from "./attache-regleur-bouton.vue?vue&type=template&id=223d7801"
import script from "./attache-regleur-bouton.vue?vue&type=script&lang=js"
export * from "./attache-regleur-bouton.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports